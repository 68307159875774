import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import { environment } from "../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: MsalService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const scopes = this.getScopesForUrl(req.url);
    if (!scopes) {
      return next.handle(req); // If no scopes are found for URL, proceed without token
    }

    return from(this.acquireToken(scopes)).pipe(
      mergeMap((token: string | null) => {
        if (token) {
          const cloned = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(cloned);
        } else {
          // Fallback to normal handling if token acquisition fails
          return next.handle(req);
        }
      })
    );
  }

  private getScopesForUrl(url: string): string[] | null {
    for (const config of environment.apiConfig) {
      if (url.startsWith(config.uri)) {
        return config.scopes;
      }
    }
    return null;
  }

  private async acquireToken(scopes: string[]): Promise<string | null> {
    try {
      const result: AuthenticationResult = await this.authService.instance.acquireTokenSilent({
        scopes: scopes,
      });
      return result.accessToken;
    } catch (error) {
      console.error("Silent token acquisition failed, acquiring via interaction", error);
      try {
        const result: AuthenticationResult = await this.authService.instance.acquireTokenPopup({
          scopes: scopes,
        });
        return result.accessToken;
      } catch (interactionError) {
        console.error("Token acquisition via interaction failed", interactionError);
        return null;
      }
    }
  }
}
