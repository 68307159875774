import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  APIResponse,
  IAction,
  IActionWithId,
  IDocumentVersionField,
  ILOSField,
  IProjectsResponse,
  IReferenceDataResponse,
  IRule,
  IRuleFilters,
  IRuleWithId,
} from "src/app/store/rule-engine/rule-engine.types";

@Injectable({
  providedIn: "root",
})
export class RuleEngineService {
  private baseUrl = "https://ruleengine-ard5cuehcmejhqg7.southindia-01.azurewebsites.net/api/v1/rules/";

  constructor(private http: HttpClient) {}

  getRules(ruleFilters: IRuleFilters) {
    let params = new HttpParams()
      .set("limit", ruleFilters.limit.toString())
      .set("offset", ruleFilters.offset.toString());

    if (ruleFilters.id) {
      params = params.set("id", ruleFilters.id);
    }
    if (ruleFilters.repeat_for_every) {
      params = params.set("repeat_for_every", ruleFilters.repeat_for_every);
    }
    if (ruleFilters.applicable_loan_type_ids) {
      params = params.set("applicable_loan_type_ids", ruleFilters.applicable_loan_type_ids.join(","));
    }
    if (ruleFilters.project_id) {
      params = params.set("project_ids", ruleFilters.project_id);
    }
    if (ruleFilters.active) {
      params = params.set("active", ruleFilters.active);
    }
    if (ruleFilters.parent_document_type_id) {
      params = params.set("parent_document_type_ids", ruleFilters.parent_document_type_id);
    }
    if (ruleFilters.in_scope_document_type_ids) {
      params = params.set("in_scope_document_type_ids", ruleFilters.in_scope_document_type_ids.join(","));
    }

    return this.http.get<{ rules: IRuleWithId[] }>(`${this.baseUrl}`, { params });
  }

  getRuleById(ruleId: string) {
    const params = new HttpParams().set("id", ruleId);
    return this.http.get<{ rules: IRuleWithId[] }>(`${this.baseUrl}`, { params });
  }

  createRule(rule: IRule) {
    return this.http.post<IRuleWithId>(`${this.baseUrl}`, rule);
  }

  updateRule(ruleId: string, rule: IRule) {
    return this.http.put<IRule>(`${this.baseUrl}${ruleId}`, rule);
  }

  deleteRule(ruleId: number) {
    return this.http.delete<void>(`${this.baseUrl}${ruleId}`);
  }

  getActions(ruleId: string) {
    return this.http.get<IActionWithId[]>(`${this.baseUrl}${ruleId}/actions`);
  }

  createAction(action: IAction, ruleId: string) {
    return this.http.post<IActionWithId>(`${this.baseUrl}${ruleId}/actions`, action, {
      headers: { "Content-Type": "application/json" },
    });
  }

  updateAction(action: IActionWithId, ruleId: string, id: string) {
    return this.http.put<IActionWithId>(`${this.baseUrl}${ruleId}/actions/${id}`, action, {
      headers: { "Content-Type": "application/json" },
    });
  }

  deleteAction(ruleId: number, actionId: string) {
    return this.http.delete<void>(`${this.baseUrl}${ruleId}/actions/${actionId}`);
  }

  updateRuleState(state: boolean, ruleId: number) {
    return this.http.put(
      `${this.baseUrl}${ruleId}/state`,
      { state: state },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  getPojects(customerId: number) {
    return this.http.get<IProjectsResponse>(
      `https://msuitecustomer-dev.azurewebsites.net/api/v1/customers/${customerId}/projects`
    );
  }

  getDocumentTypes(projectId: number, referenceTableName: string) {
    let params = new HttpParams();
    params = params.set("referenceTableName", referenceTableName);
    return this.http.get<IReferenceDataResponse>(
      `https://msuitecommon-dev.azurewebsites.net/api/v1/projects/${projectId}/referencedata/`,
      { params }
    );
  }

  getDocumentVersionFieldList(documentTypeCode: string) {
    return this.http.get<APIResponse<IDocumentVersionField[]>>(
      `https://msuitecommon-dev.azurewebsites.net/api/v1/documents/${documentTypeCode}/version-fields`
    );
  }

  getLosFieldList() {
    return this.http.get<APIResponse<ILOSField[]>>(`https://msuitecommon-dev.azurewebsites.net/api/v1/msuite-fields`);
  }
}
